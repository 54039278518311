import React from "react"

import { Layout }  from "../components/Layout/layout"
import { HeroSplit } from "../components/HeroSplit/herosplit"
import { Projects } from "../components/Projects/projects"
import { Contact } from "..//components/Contact/contact"
import { Skills } from "../components/Skills/skills"

const IndexPage = () => (
  <>
  <Layout>
    <HeroSplit />
    <Projects />
    <Contact />
    <Skills />
  </Layout>
</>
)

export default IndexPage
