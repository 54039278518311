import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Skill } from './skill'
import styles from './skills.module.css'
import { SectionHeader } from '../common/sectionHeader'
import { StaticQuery, graphql } from 'gatsby'

export const Skills = () => (

    <Container id="skills" className={styles.skillsContainer} fluid>
        <SectionHeader Text="Skills" />
        <Row className="justify-content-center">
            <StaticQuery
                query={graphql `
                    query SkillsQuery {
                        allSkillsJson {
                            edges {
                                node {
                                    Type
                                    List
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <>
                        {getSkillsData(data)}
                    </>
                )}
            />
        </Row>
    </Container>

);

function getSkillsData(data) {

    const skills = [];

    data.allSkillsJson.edges.forEach(item =>
        skills.push(
            <Col className={styles.skillContainer} xs={8} sm={10} md={8} xl={3}>
                <Skill 
                    Name={item.node.Type} 
                    List={item.node.List}
                />
            </Col>)
    );

    return skills;

}
