import React from "react"
import { Container, Col, Row, Nav } from 'react-bootstrap'
import styles from './topnav.module.css'

export const TopNav = () => (

    <Container className={styles.topnavContainer} fluid>
        <Row>
            <Col>
                <Nav defaultActiveKey="#about" className="justify-content-center">
                    <Nav.Item>
                        <Nav.Link href="#about">About</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#projects">Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#skills">Skills</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Col>
        </Row>
    </Container>
    
)