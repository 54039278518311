import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import styles from './herosplit.module.css'
import { Button } from 'react-bootstrap'
import { Particles } from 'react-particles-js'
import { useStaticQuery, graphql } from 'gatsby'


export const HeroSplit = () => {

    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        tags,
                        author
                    }
                }
                allHeroJson {
                    edges {
                        node {
                            title,
                            body
                        }
                    }
                }
            }`
    )
    return (
        <Container id="about" fluid>
            <Row>
                <Col xl={6} lg={12} md={12} sm={12} xs={12} style={{ zIndex: "-2", backgroundColor: "#eb5e28" }}>
                    <Row>
                        <Col lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 8, offset: 1}} xs={{ span: 8, offset: 1}} className={styles.left}>
                            <h1 className={styles.name}><strong>{data.site.siteMetadata.author}</strong></h1>
                            <p className={styles.titles}>

                                {getTaglines(data)}

                            </p>
                        </Col>
                    </Row>
                    <Particles 
                        className={styles.particles}
                    />
                </Col>
                <Col style={{ backgroundColor: "#252422" }}>
                    <Row>
                        <Col md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1}} xs={{ span: 10, offset: 1}} className={styles.right}>
                            <h2 className={styles.tagline}><strong>{data.allHeroJson.edges[0].node.title}</strong></h2>
                            <p className={styles.description}>{data.allHeroJson.edges[0].node.body}</p>
                            <Button href="#contact" variant="primary" size="lg">Hire Me</Button> <Button href="#projects" variant="primary" size="lg">View Projects</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
        
    
    
    function getTaglines(data) {

        const taglines = [];

        data.site.siteMetadata.tags.forEach((tag) => {

            taglines.push(
                tag
            )
            taglines.push(
                <br />
            )

        });

        return taglines;

    } 
}

