import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './contact.module.css'
import { Particles } from 'react-particles-js'
import { useStaticQuery, graphql } from 'gatsby'

export const Contact = () => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        email
                    }
                }
            }
        `
    )

    const { email } = data.site.siteMetadata;

    return (
        <Container fluid id="contact" className={styles.container}>
            <Row className={styles.headingContainer}>
                <Col className={styles.headingInner}>
                    <h1>Got work? <a href={`mailto:${email}`}>{email}</a></h1>
                    <Particles className={styles.particles} />
                   </Col>
            </Row>
        </Container>
    )

}
