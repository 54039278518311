import React from 'react'
import { Footer } from './Footer/footer'
import { TopNav } from '../TopNav/topnav'
import 'bootstrap/dist/css/bootstrap.min.css'
import './layout.css'
import SEO from '../seo'

export const Layout = ({ children }) => (
    <>
        <SEO />
        <TopNav />
        {children}
        <Footer />
    </>
)