import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import styles from './footer.module.css'

export const Footer = () => (

    <Container fluid className={styles.footer}>
        <Container>
            <Row>
                <Col>
                    Copyright © Joshua Hickey. All rights reserved.
                </Col>
            </Row>
        </Container>
    </Container>
    
)