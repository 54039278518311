import React from 'react'
import { Button } from 'react-bootstrap'
import styles from './project.module.css'

export const Project = (props) => (
<>
    <h3 className={styles.name}>{props.Name}</h3>
    <p className={styles.desc}>{props.Description}</p>
    <Button href={props.URL}>View Project</Button>
</>
)