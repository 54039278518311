import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './styles/sectionHeader.module.css'

export const SectionHeader = (props) => (

    <Row className={styles.headingContainer}>
        <Col><h1>{props.Text}{props.children}</h1></Col>
    </Row>

)