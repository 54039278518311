import React from 'react'

export const Skill = (props) => (
    <>
        <h3>{props.Name}</h3>
        <ul>
            {getListItems(props.List)}
        </ul>
    </>
)
function getListItems(data) {

    const items = [];

    data.forEach((item) => {
        items.push(
            <li>{item}</li>
        )
    })

    return items;
    
}