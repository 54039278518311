import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Project } from './project'
import styles from './projects.module.css'
import { SectionHeader } from '../common/sectionHeader'
import { StaticQuery, graphql } from 'gatsby'

export const Projects = () => (

    <Container fluid id="projects" className={styles.projectsContainer}>
        <SectionHeader Text="Projects" />
        <Row className="justify-content-center">
            <StaticQuery
                query={graphql `
                    query ProjectsQuery {
                        allProjectsJson {
                            edges {
                                node {
                                    Name
                                    Description
                                    URL
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <>
                        {getProjectElements(data)}
                    </>
                )}
            />
        </Row>
    </Container>

);

function getProjectElements(data) {

    const projects = [];

    data.allProjectsJson.edges.forEach((item) =>

        projects.push(
            <Col className={styles.projectContainer} xs={8} sm={10} md={8} xl={3}>
                <Project 
                    Name={item.node.Name} 
                    Description={item.node.Description} 
                    URL={item.node.URL}
                />
            </Col>)
    );

    return projects;

}
